import { mapGetters, mapState } from 'vuex'
import SLink from '../../../../components/ui/s-link'
import STitle from '../../../../components/ui/s-title'
import iconConnect from '@/assets/images/connect_icon.svg'
import iconHelp from '@/assets/images/icone_help.svg'
import SButton from '../../../../components/ui/s-button'

export default {
  name: 'LeftGroupActions',

  props: {
    source: {
      type: String,
    },
  },

  components: {
    SLink,
    STitle,
    SButton,
  },

  data: () => ({
    iconConnect,
    iconHelp,
    tooltipActive: false,
  }),

  computed: {
    ...mapState(['loading', 'company']),
    ...mapGetters([
      'isConnectEnabled',
      'isSourceCollaborator',
      'isFeatureEnabled',
      'isIssueFeatureEnabled',
    ]),

    issuesPage() {
      const { slug, slugId } = this.company
      return { name: 'issues', params: { slug, slugId } }
    },

    isDisclosureEnabled() {
      return this.isFeatureEnabled('disclosure')
    },

    disclosurePage() {
      const { slug, slugId } = this.company
      return { name: 'company-disclosure', params: { slug, slugId } }
    },

    isMobile() {
      return screen.width < 768
    },

    showConnect() {
      return this.isConnectEnabled && this.isSourceCollaborator(this.source)
    },
  },
}
